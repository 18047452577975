import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { OffcanvasComponent } from './components/offcanvas/offcanvas.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild()
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        OffcanvasComponent,
        TranslateModule
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        OffcanvasComponent
    ],
    providers: [],
})
export class LayoutModule { }
