import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private postUrl: string;

  constructor(private http: HttpClient, private translateService: TranslateService) {
    this.postUrl = `${environment.baseUrl}/${environment.urls.getPagePosts}`;
  }

  getPosts() {
    const url = `${this.postUrl}?_embed&lang=${this.translateService.currentLang}`;
    return this.http.get(url).pipe(catchError(this.errorHandler));
  }

  getSinglePost(id: any) {
    /*const url = `${this.postUrl}/${id}?_embed&lang=${this.translateService.currentLang}`;*/
    const url = `${this.postUrl}/${id}?_embed`;
    return this.http.get(url).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return new Observable((observer: Observer<any>) => {
      observer.error(error);
    });
  }

}


