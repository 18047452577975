<a class="custom-offcanvas-button" href="#offcanvas-usage" uk-toggle><span uk-icon="icon: menu; ratio: 2"></span></a>

<div id="offcanvas-usage" class="custom-offcanvas-menu" uk-offcanvas>
    <div class="uk-offcanvas-bar custom-offcanvas-bar">
        <button class="custom-offcanvas-close uk-offcanvas-close" type="button" uk-close></button>
        <div class="custom-offcanvas-menu-logo"><a href="#"><img src="assets/images/offcanvas/logoMint.png"></a></div>
        <ul class="uk-nav uk-nav-default">
            <!--<li class="uk-active"><a href="#">Active</a></li>-->
            <li class="custom-effect"><a class="custom-offcanvas-close-link uk-offcanvas-close uk-offcanvas-close" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'site.layout.offCanvas.menuItem1' | translate}}</a></li>
            <li class="custom-effect"><a class="custom-offcanvas-close-link uk-offcanvas-close uk-offcanvas-close" routerLink="/servicios" routerLinkActive="active">{{'site.layout.offCanvas.menuItem2' | translate}}</a></li>
            <li class="custom-effect"><a class="custom-offcanvas-close-link uk-offcanvas-close uk-offcanvas-close" routerLink="/portafolio" routerLinkActive="active">{{'site.layout.offCanvas.menuItem3' | translate}}</a></li>
            <li class="custom-effect"><a class="custom-offcanvas-close-link uk-offcanvas-close uk-offcanvas-close" routerLink="/nuestro-proceso" routerLinkActive="active">{{'site.layout.offCanvas.menuItem4' | translate}}</a></li>
            <li class="custom-effect"><a class="custom-offcanvas-close-link uk-offcanvas-close uk-offcanvas-close" routerLink="/nosotros" routerLinkActive="active">{{'site.layout.offCanvas.menuItem5' | translate}}</a></li>
            <li class="custom-effect"><a class="custom-offcanvas-close-link uk-offcanvas-close uk-offcanvas-close" routerLink="/contacto" routerLinkActive="active">{{'site.layout.offCanvas.menuItem6' | translate}}</a></li>
            <!--<li><a routerLink="/faq" routerLinkActive="active">FAQ</a></li>
            <li><a routerLink="/terminos" routerLinkActive="active">Términos</a></li>-->
            <li class="custom-effect"><a class="custom-offcanvas-close-link uk-offcanvas-close uk-offcanvas-close" routerLink="/mint-news" routerLinkActive="active">{{'site.layout.offCanvas.menuItem7' | translate}}</a></li>
        </ul>
        <div class="custom-languages">

            <form class="custom-languages-form uk-margin-large-top">
              <div class="uk-margin">
                  <div class="custom-languages-form-inner" uk-form-custom="target: > * > span:first-child">
                      <select class="custom-languages-select" (change)="changeLang($event.target.value)">
                          <option value="es" [selected]="lang == 'es'">ES</option>
                          <option value="en" [selected]="lang == 'en'">EN</option>
                      </select>
                      <button class="custom-languages-button uk-button" type="button" tabindex="-1">
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                      </button>
                  </div>
              </div>
            </form>

        </div>
    </div>
</div>



