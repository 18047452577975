import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SinglePostComponent } from './pages/mint-news/components/single-post/single-post.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), pathMatch:'full'},
  { path: 'portafolio', loadChildren: () => import('./pages/portfolio/portfolio.module').then(m => m.PortfolioModule)},
  { path: 'portafolio/midtown', loadChildren: () => import('./pages/midtown/midtown.module').then(m => m.MidtownModule)},
  { path: 'portafolio/midtown-penthouse', loadChildren: () => import('./pages/midtown-penthouse/midtown-penthouse.module').then(m => m.MidtownPenthouseModule)},
  { path: 'portafolio/aria-on-the-bay', loadChildren: () => import('./pages/aria-on-the-bay/aria-on-the-bay.module').then(m => m.AriaOnTheBayModule)},
  { path: 'portafolio/elysse', loadChildren: () => import('./pages/elysse/elysse.module').then(m => m.ElysseModule)},
  { path: 'portafolio/mint-wellness-spa', loadChildren: () => import('./pages/mint-wellness-spa/mint-wellness-spa.module').then(m => m.MintWellnessSpaModule)},
  { path: 'nuestro-proceso', loadChildren: () => import('./pages/process/process.module').then(m => m.ProcessModule)},
  { path: 'servicios', loadChildren: () => import('./pages/service/service.module').then(m => m.ServiceModule)},
  { path: 'diseno-arquitectonico', loadChildren: () => import('./pages/diseno-arquitectonico/diseno-arquitectonico.module').then(m => m.DisenoArquitectonicoModule)},
  { path: 'decoracion', loadChildren: () => import('./pages/decoracion/decoracion.module').then(m => m.DecoracionModule)},
  { path: 'interiorismo', loadChildren: () => import('./pages/interiorismo/interiorismo.module').then(m => m.InteriorismoModule)},
  { path: 'muebles-a-medida', loadChildren: () => import('./pages/muebles-a-medida/muebles-a-medida.module').then(m => m.MueblesAMedidaModule)},
  { path: 'nosotros', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)},
  { path: 'contacto', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)},
  { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)},
  { path: 'terminos', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule)},
  { path: 'mint-news', loadChildren: () => import('./pages/mint-news/mint-news.module').then(m => m.MintNewsModule)},

  { path: 'posts/:id', component: SinglePostComponent },
  { path: '**', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), pathMatch:'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
