import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.scss']
})
export class OffcanvasComponent implements OnInit {

  lang: any;

  constructor() { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') || 'es';
  }

  changeLang(lang){
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

}
