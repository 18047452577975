<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#AC9874" type="square-jelly-box" [fullScreen]="true">
  <p style="color: #AC9874"> {{'site.loading' | translate}} </p>
</ngx-spinner>

<div class="custom-header-image">
  <div class="custom-slider-home-inner image-background uk-position-relative uk-light" uk-slideshow tabindex="-1" uk-slideshow="animation: push" autoplay>
    <ul class="uk-slideshow-items" uk-height-viewport="min-height: 300">
        <li>
          <img src="../../../assets/images/mint-news/image-head-mint-news.jpg" alt="" uk-cover>
            <article class="custom-content-header uk-comment uk-comment-primary uk-position-large uk-position-bottom-right">
              <header class="uk-comment-header">
                  <div class="uk-grid-medium uk-flex-middle" uk-grid>
                      <div class="custom-vertical-line uk-width-auto">
                        <hr class="custom-divider-h uk-divider-vertical">
                      </div>
                      <div class="custom-content-header-inner uk-width-expand">
                          <div class="custom-header-image-title uk-comment-title uk-margin-remove">{{'site.pages.mintNews.mintNewHeader' | translate}}</div>
                          <ul class="custom-header-image-description uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                              <li></li>
                          </ul>
                      </div>
                  </div>
              </header>
            </article>
          </li>
    </ul>
  </div>
</div>

<div class="mint-news-single" style="background-image: url(assets/images/home/textureBackground-reduced.jpg);">
    <div class="uk-container">
      <div id="layout" class="pure-g">
        <div class="content pure-u-1 pure-u-md-3-4">
          <div>
            <div class="posts">
              <section class="post" *ngIf="singlePost">
                <header class="post-header">
                  <div class="post-title" [innerHTML]="singlePost.title.rendered "></div>
                </header>

                <!--Imagen-->
                <div class="custom-post-image">
                  <img [src]="singlePost['_embedded']['wp:featuredmedia'][0]['media_details'].sizes['full'].source_url">
                </div>

                <div class="post-description">
                  <p [innerHTML]="singlePost.content.rendered">
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>


