import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.scss']
})
export class SinglePostComponent implements OnInit {

  id!: string;
  singlePost: any;
  errorMessage: any;

  lang: any;

  constructor(private route:Router, private activeRoute: ActivatedRoute, private blogService: BlogService, private spinner: NgxSpinnerService, private translateService: TranslateService) { }

  ngOnInit() {
    this.id = this.activeRoute.snapshot.paramMap.get('id') as string;
    console.log(this.id);

    this.getSinglePost();

    this.lang = localStorage.getItem('lang') || 'es';

  }

  getSinglePost() {
    this.spinner.show();
    this.blogService.getSinglePost(this.id).pipe(
      switchMap(post => {

        if(post.lang !== this.translateService.currentLang){
          this.route.navigate(['/posts', post.translations[this.translateService.currentLang]]);
          return this.blogService.getSinglePost(post.translations[this.translateService.currentLang]);
        }

        return of(post);
      })
    )
    .subscribe(
      (data) => {
        this.singlePost = data;
        console.log(this.singlePost);
        this.spinner.hide();
      },
      (error) => {
        this.errorMessage = error.error.message;
        console.log(error.error.message, 'error');
        this.spinner.hide();
      }
    );
  }

  changeLang(lang){
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

}
