// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //endPointInstragram: "https://v1.nocodeapi.com/juanitourquiza/instagram/rdwSHzzjdpcNJmKs",
  endPointInstragram: "https://wordpress-557735-1945486.cloudwaysapps.com/instagram.php",
  baseUrl: "https://wordpress-557735-1945486.cloudwaysapps.com/wp-json",
  urls: {
    getPage: "wp/v2/pages",
    getPageCustomFields: "acf/v3/pages",
    getPagePosts: "wp/v2/posts"
  },
  pages: {
    home: {
      id: 8
    },
    servicios: {
      id: 667
    },
    nuestroProceso: {
      id: 729
    },
    nosotros: {
      id: 809
    },
    terminosCondiciones: {
      id: 839
    },
    preguntasFrecuentes: {
      id: 849
    },
    contacto: {
      id: 971
    },
    portafolio: {
      id: 94
    },
    midtown: {
      id: 138
    },
    midtownPenthouse: {
      id: 168
    },
    ariaOnTheBay: {
      id: 255
    },
    elysee: {
      id: 458
    },
    mintWellnessSpa: {
      id: 574
    },
    decoracion: {
      id: 363
    },
    interiorismo: {
      id: 502
    },
    disenoArquitectonico: {
      id: 414
    },
    mueblesMedida: {
      id: 615
    }
  }
};
