<div class="custom-texture-background-footer uk-background-cover" style="background-image: url(assets/images/footer/textureImageFooter.jpg);">
    <div class="custom-footer">
        <div class="uk-text-center">
            <div class="custom-footer-head">{{'site.layout.footer.head' | translate}}</div>
            <div class="custom-text-content-button-footer"><a routerLink="/contacto">{{'site.layout.footer.button' | translate}}</a></div>
        </div>

        <div class="custom-footer-socials uk-flex uk-flex-center">
          <a href="https://www.facebook.com/mintdesignmiami" class="uk-icon-button uk-margin-small-right" uk-icon="facebook" target="_blank"></a>
          <a href="https://www.instagram.com/mintdesignmiami/?hl=es" class="uk-icon-button  uk-margin-small-right" uk-icon="instagram" target="_blank"></a>
        </div>

        <div class="custom-footer-menu uk-grid-match uk-grid-small uk-text-center" uk-grid>
          <div class="uk-width-1-4@m uk-visible@m">
              <div class="custom-footer-menu-item"></div>
          </div>
          <div class="uk-width-1-6@m">
              <div class="custom-footer-menu-item"><a routerLink="/">{{'site.layout.footer.linkHome' | translate}}</a></div>
          </div>
          <div class="uk-width-1-6@m">
              <div class="custom-footer-menu-item"><a routerLink="/faq">{{'site.layout.footer.linkFaq' | translate}}</a></div>
          </div>
          <div class="uk-width-1-6@m">
              <div class="custom-footer-menu-item"><a routerLink="/terminos">{{'site.layout.footer.linkTerms' | translate}}</a></div>
          </div>
          <div class="uk-width-1-4@m uk-visible@l">
              <div class="custom-footer-menu-item"></div>
          </div>
        </div>
    </div>
</div>
<div class="custom-copy-right uk-text-center">
    <div class="custom-copy-right-content">Copyright © Mint Design Powered by <a href="https://e-channel.ec/" target="_blank"><img src="assets/images/footer/logoEchannel.png"></a></div>
</div>



