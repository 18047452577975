import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BackgroundParallaxComponent } from './components/background-parallax/background-parallax.component';
import { RightImageComponent } from './components/right-image/right-image.component';
import { LeftImageComponent } from './components/left-image/left-image.component';
import { HeaderImageComponent } from './components/header-image/header-image.component';
import { LogosComponent } from './components/logos/logos.component';
import { GalleryMassonryComponent } from './components/gallery-project/gallery-massonry.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BackgroundParallaxComponent,
    RightImageComponent,
    LeftImageComponent,
    HeaderImageComponent,
    LogosComponent,
    GalleryMassonryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  exports: [
    BackgroundParallaxComponent,
    RightImageComponent,
    LeftImageComponent,
    HeaderImageComponent,
    LogosComponent,
    GalleryMassonryComponent,
    TranslateModule
  ]
})
export class SharedModule { }

